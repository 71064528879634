import { useEffect, useState } from "react";
import { Content } from "../../../components/content/content";
import { Footer } from "../../../components/footer/footer";
import { Nav } from "../../../components/nav/nav";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { Helmet } from "react-helmet";
import PhoneInputWithCountrySelect, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { Bkg } from "../../../components/content/bkg";
import { t } from "../../../utils/cms";
import CountryList from '../../../assets/json/countries.json';
import { Link, useNavigate } from "react-router-dom"; // Import Link from react-router-dom
import { ReactComponent as EyeIcon } from '../../../assets/svgs/eye.svg';
import { ReactComponent as EyeSlashIcon } from '../../../assets/svgs/eye-slash.svg';
import RangeSlider from "../../../components/pages/register/range-slider";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const PrioritisedCountries = [
  "GB",
  "US",
  "CA",
  "FR",
  "DE",
  "IT",
  "ES",
]

const Countries = PrioritisedCountries.map(code => ({
  code,
  name: CountryList.find(country => country.code === code)?.name
})).concat(CountryList.filter(country => !PrioritisedCountries.includes(country.code)).map(country => ({
  code: country.code,
  name: country.name
})));

export function AccountRegisterPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("UK");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [infoMessage, setInfoMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isChecked, setIsChecked] = useState({
    over18: false,
    termsAgreed: false,
    qualifiedInvestor: false,
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const [aggression, setAggression] = useState('Conservative');
  const [duration, setDuration] = useState(2);
  const [deviceId, setDeviceId] = useState<string>('');

  const [register, { data: registerData, error: registerError }] = useMutation(
    gql`
      mutation registerUser(
        $email: String!
        $phone: String!
        $password: String!
        $firstName: String!
        $lastName: String!
        $country: String!
        $investmentDuration: Float
        $investmentApproach: String
        $deviceId: String!
        $osVersion: String!
        $deviceType: String!
        $model: String!
      ) {
        register(
          data: {
            email: $email
            phone: $phone
            password: $password
            firstName: $firstName
            lastName: $lastName
            country: $country
            investmentDuration: $investmentDuration
            investmentApproach: $investmentApproach
          }
          deviceData: {
            deviceId: $deviceId
            osVersion: $osVersion
            deviceType: $deviceType
            model: $model
          }
        ) {
          id
          firstName
          lastName
          email
        }
      }
    `,
    { errorPolicy: "all" }
  );

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  function validateEmail(emailTest: string) {
    return emailRegex.test(emailTest);
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setIsChecked(prevState => ({
      ...prevState,
      [id]: checked,
    }));
  };

  useEffect(() => {
    FingerprintJS.load().then(fp => {
      fp.get().then(result => {
        setDeviceId(result.visitorId);
      });
    });
  }, []);

  const userAgent = navigator.userAgent;
  const osVersion = getOSVersion(userAgent);
  const deviceType = getDeviceType(userAgent);
  const model = getModel(userAgent);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setInfoMessage(undefined);
    setErrorMessage(undefined);
    setLoading(true);
    setSubmitted(false);

    const { over18, termsAgreed, qualifiedInvestor } = isChecked;

    // Validate form fields
    if (!firstName || !lastName || !email || !phone || !password || !country) {
      setLoading(false);
      setErrorMessage(t("accountRegister.Form Feedback.form_validation_error_account_register"));
      return;
    }
    if (!over18 || !termsAgreed || !qualifiedInvestor) {
      setLoading(false);
      setErrorMessage("Please accept all agreements to create your account.");
      return;
    }
    if (!validateEmail(email)) {
      setLoading(false);
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    if (password.length < 8) {
      setLoading(false);
      setErrorMessage("Password must be at least 8 characters long.");
      return;
    }

    // Validate phone number
    if (!isValidPhoneNumber(phone)) {
      setLoading(false);
      setErrorMessage(t("accountRegister.Form Feedback.form_phone_error_account_register"));
      return;
    }

    // Convert to international format
    const parsedPhoneNumber = parsePhoneNumber(phone);
    const internationalPhoneNumber = parsedPhoneNumber?.formatInternational() ?? "";

    setInfoMessage(t("accountRegister.Form Feedback.form_info_message_account_register"));

    // Submit form
    const data = {
      firstName,
      lastName,
      phone: internationalPhoneNumber,
      password,
      email,
      country,
      investmentDuration: undefined,
      investmentApproach: undefined,
    };

    const deviceData = {
      deviceId,
      osVersion,
      deviceType,
      model,
    };

    await register({ variables: { ...data, ...deviceData } });
  }

  useEffect(() => {
    if (registerError) {
      console.error("Registration error:", registerError);
      setLoading(false);
      setInfoMessage(undefined);
      setErrorMessage(registerError.message);
    } else if (registerData) {
      setLoading(false);
      setSubmitted(true);
      setInfoMessage("Your request has been submitted successfully. Please check your email for the next steps.");
      const investor_portal = process.env.REACT_APP_INVESTOR_PORTAL_URL;
      if (investor_portal) {
        window.location.href = investor_portal;
      }
    }
  }, [registerData, registerError]);

  const onAggressionChange = (value: string | number) => {
    setAggression(value as string);
  };

  const onDurationChange = (value: string | number) => {
    setDuration(value as number);
  };

  function getOSVersion(userAgent: string) {
    let osVersion = 'Unknown OS Version';

    if (/Windows NT 10.0/.test(userAgent)) osVersion = 'Windows 10';
    else if (/Windows NT 6.3/.test(userAgent)) osVersion = 'Windows 8.1';
    else if (/Windows NT 6.2/.test(userAgent)) osVersion = 'Windows 8';
    else if (/Windows NT 6.1/.test(userAgent)) osVersion = 'Windows 7';
    else if (/Mac OS X/.test(userAgent)) {
      const match = userAgent.match(/Mac OS X (\d+[\._]\d+[\._]?\d*)/);
      if (match) osVersion = `macOS ${match[1].replace(/_/g, '.')}`;
    } else if (/Android/.test(userAgent)) {
      const match = userAgent.match(/Android\s([0-9\.]*)/);
      if (match) osVersion = `Android ${match[1]}`;
    } else if (/iPhone OS/.test(userAgent)) {
      const match = userAgent.match(/iPhone OS (\d+[\._]\d+)/);
      if (match) osVersion = `iOS ${match[1].replace(/_/g, '.')}`;
    }

    return osVersion;
  }

  function getDeviceType(userAgent: string) {
    if (/android/i.test(userAgent)) return 'android';
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) return 'ios';
    return 'browser';
  }


  function getModel(userAgent: string) {
    let model = 'Unknown Model';

    if (/iPhone/.test(userAgent)) model = 'iPhone';
    else if (/iPad/.test(userAgent)) model = 'iPad';
    else if (/Macintosh/.test(userAgent)) model = 'Mac';
    else if (/Windows NT/.test(userAgent)) model = 'Windows PC';
    else if (/Android/.test(userAgent)) {
      const match = userAgent.match(/Android.*;\s([a-zA-Z0-9\s]+)\sBuild/);
      if (match) model = match[1];
    }

    return model;
  }

  return (
    <>
      <Helmet>
        <title>{t("accountRegister.Helmet.helmet_title_account_register")}</title>
        <meta name={t("accountRegister.Helmet.helmet_meta_name_account_register")} content={t("accountRegister.Helmet.helmet_meta_content_account_register")} />
      </Helmet>

      <Nav />

      <Bkg type="bottom" />

      <Content className="flex account flex-column">
        <h2>{t("accountRegister.Intro.intro_heading_account_register")}</h2>
        <p>{t("accountRegister.Intro.intro_description_account_register")}</p>
        <form className="flex flex-1 mb-5 flex-column gap" onSubmit={handleSubmit}>
          <h3>{t("accountRegister.Form General.form_heading_account_register")}</h3>

          {!registerData ? (
            <>
              <div className="flex flex-column">
                <label htmlFor="first-name">{t("accountRegister.Form Fields First Name.first_name_label_account_register")}</label>
                <input
                  type="text"
                  id="first-name"
                  name="first-name"
                  placeholder={t("accountRegister.Form Fields First Name.first_name_placeholder_account_register")}
                  autoFocus
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                />
              </div>
              <div className="flex flex-column">
                <label htmlFor="last-name">{t("accountRegister.Form Fields Last Name.last_name_label_account_register")}</label>
                <input
                  type="text"
                  id="last-name"
                  name="last-name"
                  placeholder={t("accountRegister.Form Fields Last Name.last_name_placeholder_account_register")}
                  onChange={(e) => setLastName(e.currentTarget.value)}
                />
              </div>
              <div className="flex flex-column">
                <label htmlFor="email">{t("accountRegister.Form Fields Email.email_label_account_register")}</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder={t("accountRegister.Form Fields Email.email_placeholder_account_register")}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </div>
              <div className="flex flex-column">
                <label htmlFor="password">{t("accountRegister.Form Fields Password.password_label_account_register")}</label>
                {/* <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder={t("accountRegister.Form Fields Password.password_placeholder_account_register")}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                /> */}
                <div className="password-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    name="password"
                    placeholder={t("accountRegister.Form Fields Password.password_placeholder_account_register")}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    className="password-input"
                  />
                  <button
                    type="button"
                    className="password-toggle-button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <EyeIcon className="icon" /> : <EyeSlashIcon className="icon" />}
                  </button>
                </div>
              </div>
              <div className="flex flex-column">
                <label htmlFor="phone">{t("accountRegister.Form Fields Phone.phone_label_account_register")}</label>
                <PhoneInputWithCountrySelect
                  defaultCountry="GB"
                  placeholder={t("accountRegister.Form Fields Phone.placeholder_phone_account_register")}
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e ? e.toString() : "")}
                />
              </div>
              <div className="flex flex-column">
                <label htmlFor="country">{t("accountRegister.Form Fields Country.label_country_residence_account_register")}</label>
                <select
                  id="country"
                  name="country"
                  onChange={(e) => setCountry(e.currentTarget.value)}
                >
                  {Countries.map((country) => (
                    <option key={country.code} value={country.code}>{country.name}</option>
                  ))}
                </select>
              </div>

              {infoMessage && <div className="info-message">{infoMessage}</div>}
              {errorMessage && <div className="error-message">{errorMessage}</div>}

              {/* <div style={{ marginBottom: "1rem" }}>
                <RangeSlider
                  title="Overall Investing Approach"
                  startTitle="Conservative"
                  midTitle="Medium"
                  endTitle="Aggressive"
                  isOverall={true}
                  onValueChange={onAggressionChange}
                />
                <RangeSlider
                  title="Investment Duration Plan"
                  startTitle="2 Years"
                  endTitle="7 Years"
                  isOverall={false}
                  onValueChange={onDurationChange}
                />
              </div> */}

              {/* Checkboxes for Qualified Investor */}
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="over18"
                  name="over18"
                  checked={isChecked.over18}
                  onChange={handleCheckboxChange}
                  className="custom-checkbox"
                />
                <label htmlFor="over18" className="checkbox-label">
                  I am over the age of 18.
                </label>
              </div>

              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="qualifiedInvestor"
                  name="qualifiedInvestor"
                  checked={isChecked.qualifiedInvestor}
                  onChange={handleCheckboxChange}
                  className="custom-checkbox"
                />
                <label htmlFor="qualifiedInvestor" className="checkbox-label">
                  I confirm I am a suitably qualified investor.
                  <a href="/qualified-investor" className="learn-more-link" target="_blank" rel="noopener noreferrer">Learn more</a>
                </label>
              </div>

              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="termsAgreed"
                  name="termsAgreed"
                  checked={isChecked.termsAgreed}
                  onChange={handleCheckboxChange}
                  className="custom-checkbox"
                />
                <label htmlFor="termsAgreed" className="checkbox-label">
                  I agree to the Terms of Service.
                  <a href="/terms" className="learn-more-link" target="_blank" rel="noopener noreferrer">
                    Terms of Service
                  </a>
                </label>
              </div>

              <div className="actions">
                <button type="submit" className="btn btn-primary btn-square" disabled={loading}>
                  {loading ? t("accountRegister.Form Buttons.form_submit_button_loading_text_account_register") : t("accountRegister.Form Buttons.form_submit_button_text_account_register")}
                </button>
              </div>
            </>
          ) : (
            <>
              {/* <h3>{t("accountRegister.Next Steps.next_steps_heading_account_register")}</h3>
              <p>{t("accountRegister.Next Steps.next_steps_description_account_register")}</p>
              <ul>
                <li>
                  <b>{t("accountRegister.Next Steps Items Identity Document.title_identity_document_account_register")}</b> {t("accountRegister.Next Steps Items Identity Document.description_identity_document_account_register")}
                </li>
                <li>
                  <b>{t("accountRegister.Next Steps Items Selfie Verification.title_selfie_verification_account_register")}</b> {t("accountRegister.Next Steps Items Selfie Verification.description_selfie_verification_account_register")}
                </li>
              </ul>
              <p>{t("accountRegister.Next Steps.next_steps_closing_account_register")}</p>
              <p>{t("accountRegister.Next Steps.next_steps_privacy_account_register")}</p> */}
            </>
          )}
        </form>
      </Content>

      <Footer />
    </>
  );
}
