import React, { useEffect, useState } from "react";
import { Content } from "../../components/content/content";
import ImgLogo from './../../assets/img/common/logo_white.svg';
import PhoneInput from 'react-phone-number-input';
import { Checkbox, Slide, Slider } from "@mui/material";
import { MUTATION_ADD_INVESTOR_LEAD } from "../../graphql/mutations/investor-lead";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Helmet } from "react-helmet";
import { QUERY_GET_PROPERTY_NAMES } from "../../graphql/queries/property";
import { toHumanReadableCurrency, toHumanReadableCurrencyDisplay } from "../../utils/currency.util";
import { t } from "../../utils/cms";

export function WelcomePage() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
    const [isQualifiedInvestor, setIsQualifiedInvestor] = useState(false);
    const [registerInterest, { data, error, loading }] = useMutation(MUTATION_ADD_INVESTOR_LEAD, { errorPolicy: 'all' });
    // const [getPropertyNames, { data: propertiesData, loading: propertiesLoading, error: propertiesError }] = useLazyQuery(QUERY_GET_PROPERTY_NAMES, { errorPolicy: 'all' });
    // const [selectedProperty, setSelectedProperty] = useState<string>('');
    // const [investmentAmount, setInvestmentAmount] = useState<number | number[]>(2000);

    // const handleSliderChange = (event: Event, newValue: number | number[]) => {
    //     setInvestmentAmount(newValue as number);
    // };

    useEffect(() => {
        //need to fix this by using tailwind in rest of the project of conveting this page to standard css
        import('./../../assets/styles/welcome.css');
        //getPropertyNames();
    }, []);

    function submit(event: any) {
        event.preventDefault();
        registerInterest({
            variables: {
                email: email,
                fullName: (firstName + " " + lastName),
                phoneNumber: phoneNumber,
                utm_url: "https://propnerd.io",
                utm_source: "Landing 1",
                utm_medium: "Website",
                // preferredPropertyId: selectedProperty,
                // expectInvestmentAmount: investmentAmount as number
            }
        });
    }

    return (
        <>
            <Helmet>
                <script>
                    {`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '2211179079087801');
                    fbq('track', 'PageView');
                `}
                </script>
            </Helmet>
            <noscript>
                <img height="1" width="1" style={{ display: 'none' }} src="https://www.facebook.com/tr?id=2211179079087801&ev=PageView&noscript=1" />
            </noscript>
            <div className="welcome-container relative flex flex-wrap justify-center items-center">
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="max-w-7xl relative">
                    <img className="md:mt-12 md:ml-4 logo mx-auto md:mx-4" src={ImgLogo} alt="PropNerd" />
                    <div className="flex flex-wrap gap-12 ">
                        <div className="flex flex-col text-white max-w-2xl mt-12 md:mt-32 mx-4 text-center md:text-left">
                            <p className="md:leading-[5rem] text-2xl md:text-6xl md:mb-5 mb-3 font-bold">
                                <span className="text-text-yellow">The Future of Property Investment </span>
                                <span className="text-white">Starts Now with PropNerd</span>
                            </p>
                            <p className="text-xl sm:text-4xl max-w-xl font-semibold text-white">
                                <span className="text-text-yellow font-bold" >YOU</span> can <span className="text-text-yellow  font-bold">NOW</span> own prime real estate for as little as
                                <span className="text-text-yellow font-bold"> £1,000</span>
                            </p>
                        </div>
                        <div className="md:-mt-20 mb-0 md:mb-44">
                            <div className="card max-w-sm mx-4 bg-card-bg mb-44">
                                <p className={`text-xl text-deep-blue font-bold mb-4 ${data && 'hidden'}`}>{t("welcome.guide download.download_guide_title")}</p>
                                <p className={`text-xs font-semibold text-black mb-3 ${data && 'hidden'}`}>{t("welcome.guide download.download_guide_subtitle")}</p>
                                <form className={`${data && 'hidden'} mt-8`} onSubmit={submit} >
                                    <div className="flex flex-col gap-3">
                                        <div className="flex flex-col gap-3">
                                            <p className="text-sm font-semibold text-black ">{t("welcome.form fields.form_fields_first_name")}</p>
                                            <input type="text" id="first-name" name="first-name" required autoFocus onChange={e => setFirstName(e.currentTarget.value)} className="form-input" />
                                        </div>
                                        <div className="flex flex-col gap-3">
                                            <p className="text-sm font-semibold text-black  ">{t("welcome.form fields.form_fields_last_name")}</p>
                                            <input type="text" id="last-name" name="last-name" required onChange={e => setLastName(e.currentTarget.value)} className="form-input" />
                                        </div>
                                        <p className="text-sm font-semibold text-black mb--2">{t("welcome.form fields.form_fields_email")}</p>
                                        <input type="email" id="email" name="email" required onChange={e => setEmail(e.currentTarget.value)} className="form-input" />
                                        <p className="text-sm font-semibold text-black mb--2">{t("welcome.form fields.form_fields_phone_number")}</p>
                                        <input type="number" id="phone" name="phone" required onChange={e => setPhoneNumber(e.currentTarget.value)} className="form-input" />
                                        {/* <p className="text-sm font-semibold text-black mb--2">Preferred property*</p>
                                        <select name="property" id="property" className="form-input" onChange={(e)=> {setSelectedProperty(e.target.value)}}>
                                            <option value="none">Select property</option>
                                            {propertiesData?.publicProperties.map((property: any) => (
                                                <option key={property.id} value={property.id}>{property.title}</option>
                                            ))}
                                        </select>
                                        <p className="text-sm font-semibold text-black mb--2">Expected investment range*</p>
                                        <p className="text-xs font-semibold text-black mb-2">{toHumanReadableCurrency(investmentAmount as number * 100,0)}</p>
                                        <Slider
                                            value={investmentAmount}
                                            onChange={handleSliderChange}
                                            min={2000}
                                            step={1000}
                                            max={50000}
                                            valueLabelDisplay="auto"
                                            aria-labelledby="investment-slider"
                                            sx={{
                                                '& .MuiSlider-thumb': {
                                                color: '#e2af17',
                                                },
                                                '& .MuiSlider-track': {
                                                color: '#e2af17',
                                                },
                                                '& .MuiSlider-rail': {
                                                color: '#e2af17',
                                                },
                                            }}
                                            /> */}
                                        <div className="flex items-center mt-2">
                                            <input type="checkbox" id="qualified-investor" name="qualified-investor" onChange={e => setIsQualifiedInvestor(e.currentTarget.checked)} required className="form-checkbox" />
                                            <label htmlFor="qualified-investor" className="ml-2 text-xs font-semibold text-black">{t("welcome.form fields.form_fields_confirm_investor")}</label>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary w-full btn-square rounded-2xl mt-4" disabled={loading}>{!loading ? 'Download Now' : 'Sending email...'}</button>
                                </form>

                                <p className={`text-xl text-deep-blue font-bold mb-4 ${!data && 'hidden'}`}>{t("welcome.email process.check_email_title")}</p>
                                <p className={`text-xs font-bold text-black pb-96 mb-12 ${!data && 'hidden'}`}>{t("welcome.email process.check_email_subtitle")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full bg-white font-medium text-black sm:mt-0">
                <div className="py-10 px-4 w-full flex justify-center bg-gray-100 text-sm">
                <p className="max-w-7xl">{t('welcome.legal.disclaimer_text')}</p>
                </div>
                <div className="flex w-full flex-col justify-center items-center px-4 py-4">
                    <div className="max-w-7xl py-12">
                        <p className="font-bold text-md">{t('welcome.qualified investors.qualified_investors_title')}</p>
                        <p className="mt-2">
                            <span className="font-bold text-xs mb-2">{t('welcome.qualified investors.qualified_investors_high_net_worth_individuals_title')}</span><br />
                        </p>
                        <p className="py-2 text-xs">{t('welcome.qualified investors.qualified_investors_high_net_worth_individuals_description')}</p>
                        <p className="mt-2 text-xs">
                            <span className="font-bold text-xs mb-2">{t('welcome.qualified investors.qualified_investors_self_certified_sophisticated_investors_title')}</span><br />
                        </p>
                        <p className="py-2 text-xs">{t('welcome.qualified investors.qualified_investors_self_certified_sophisticated_investors_description')}</p>
                    </div>
                </div>
                <div className="px-4 py-4 w-full flex justify-center bg-gray-100 text-xs">
                    <div className="max-w-7xl w-full flex flex-wrap justify-between">
                        <p>{t('welcome.footer information.footer_rights_reserved')}</p>
                        <a href="https://www.propnerd.io" className="text-blue-500 underline">{t('welcome.footer information.footer_website_link')}</a>
                    </div>
                </div>
            </div>
        </>
    );
}
