import axios from 'axios';
import { useEffect, useState } from "react";
import { Content } from "../../../components/content/content";
import { Footer } from "../../../components/footer/footer";
import { Nav } from "../../../components/nav/nav";

import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { Bkg } from '../../../components/content/bkg';
import { t } from '../../../utils/cms';

export function AccountLoginPage() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [infoMessage, setInfoMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const query = useQuery();

  useEffect(() => {
    const _email = query.get('email');
    if (_email) {
      setEmail(_email);
    }
  }, [query]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setInfoMessage(undefined);
    setErrorMessage(undefined);
    setLoading(true);

    // Validate form fields
    if (!email || !password) {
      setLoading(false);
      setErrorMessage(t('accountLogin.Form Feedback.form_validation_error'));
      return;
    }

    // Login request
    const data = {
      email,
      password
    };

    try {
      // Replace with your actual login endpoint
      await axios.post('https://propnerd-api.thyreality.co.uk/account/login', data);
      setLoading(false);
      setInfoMessage(t('accountLogin.Form Feedback.form_info_message'));
      // Redirect or update UI accordingly
    } catch (error) {
      console.error('Login error:', error);
      setLoading(false);
      setErrorMessage(t('accountLogin.Form Feedback.form_error_message'));
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('accountLogin.Helmet.helmet_title')}</title>
        <meta name={t('accountLogin.Helmet.helmet_meta_name')} content={t('accountLogin.Helmet.helmet_meta_content')} />
      </Helmet>

      <Nav />

      <Bkg type='bottom' />

      <Content className="account">
        <div className="intro">
          <h2>{t('accountLogin.Intro.intro_heading')}</h2>
          <p>{t('accountLogin.Intro.intro_description')}</p>
        </div>
        <form className="form" onSubmit={handleSubmit}>
          <h2>{t('accountLogin.Form General.form_heading')}</h2>
          <p>{t('accountLogin.Form General.form_description')}</p>

          <div className="form-group">
            <label htmlFor="email">{t('accountLogin.Form Fields Email.email_label')}</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              placeholder={t('accountLogin.Form Fields Email.email_placeholder')}
              autoFocus
              onChange={e => setEmail(e.currentTarget.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">{t('accountLogin.Form Fields Password.password_label')}</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder={t('accountLogin.Form Fields Password.password_placeholder')}
              onChange={e => setPassword(e.currentTarget.value)}
            />
          </div>

          {infoMessage && <div className="info-message">{infoMessage}</div>}
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          
          <div className="form-group">
            <button type="submit" className="btn btn-wide btn-bold" disabled={loading}>
              {loading ? t('accountLogin.Form Buttons.form_submit_button_loading_text') : t('accountLogin.Form Buttons.form_submit_button_text')}
            </button>
          </div>
        </form>
      </Content>

      <Footer />
    </>
  );
}
